import { NgModule } from '@angular/core';
import { redirectLoggedInTo } from '@angular/fire/auth-guard';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthorizeGuard } from 'src/app/shared/guards/authorize.guard';
import { ROLES } from './app.const';
import { AuthenticateViaPhoneComponent } from './pages/auth/authenticate-using-phone/authenticate-using-phone.component';
import { CreateAccountComponent } from './pages/auth/create-your-account/create-account.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { SigninComponent } from './pages/auth/signin/signin.component';
import { SignoutComponent } from './pages/auth/signout/signout.component';
import { SignupQuestionaireComponent } from './pages/auth/signup-with-questionaire/signup.component';
import { VerifyEmailAndMobileComponent } from './pages/auth/verify-email-mobile/verify-email-mobile.component';
import { VerifyPhoneComponent } from './pages/auth/verify-phone/verify-phone.component';
import { ThankYouComponent } from './pages/private/subscriber/thank-you/thank-you.component';
import { YouAreInComponent } from './pages/private/subscriber/you-are-in/you-are-in.component';
import { PhoneNumberComponent, QuizStyle1Component, QuizStyleChoiceComponent } from './pages/public';
import { QuizGuard } from './shared/guards/quiz.guard';
import { RootGuard } from './shared/guards/root.guard';
import { SignupGuard } from './shared/guards/signup.guard';
import { SharedModule } from './shared/modules/shared.module';
import { AppComponent } from './app.component';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';

const routes: Routes = [
  {
    path: '', canActivate: [RootGuard],
    component: AppComponent,
    children: [
      { path: '', loadChildren: () => import('./pages/public/home/home.module').then(m => m.HomeModule) },
      { path: 'home', loadChildren: () => import('./pages/public/home/home.module').then(m => m.HomeModule) },
      { path: 'style-quiz', component: QuizStyle1Component, canActivate: [QuizGuard], data: { title: 'Your Styles' } },
      { path: 'brand-quiz', component: QuizStyleChoiceComponent, data: { title: 'Your Style, Your Choice' } },
      { path: 'phone-number', component: PhoneNumberComponent, canActivate: [SignupGuard], data: { title: 'Your Phone Number' } },
      { path: 'auth-via-phone', component: AuthenticateViaPhoneComponent, data: { title: 'Verify Your Number' } },
      { path: 'reset-password', component: ResetPasswordComponent, data: { title: 'Reset Password' } },
      { path: 'verify-your-phone', component: VerifyPhoneComponent, data: { title: 'Verify Your Number' } },
      { path: 'create-your-account', component: CreateAccountComponent, data: { title: 'Create Account' } },
      { path: 'quiz-extra-info', data: { title: 'Other Info' }, loadComponent: () => import('./pages/public/quiz-extra-info/quiz-extra-info.component').then(c => c.QuizExtraInfoComponent) },
      { path: 'our-story', data: { title: 'Our Story' }, loadComponent: () => import('./pages/public/our-story/our-story.component').then(c => c.OurStoryComponent) },
      { path: 'contact-us', data: { title: 'Contact Us' }, loadComponent: () => import('./pages/public/contact-us/contact-us.component').then(c => c.ContactUsComponent) },
      { path: 'privacy-policy', data: { title: 'Privacy Policy' }, loadComponent: () => import('./pages/public/privacy-policy/privacy-policy.component').then(c => c.PrivacyPolicyComponent) },
      { path: 'shipping-policy', data: { title: 'Shipping Policy' }, loadComponent: () => import('./pages/public/shipping-policy/shipping-policy.component').then(c => c.ShippingPolicyComponent) },
      { path: 'terms', data: { title: 'Terms Of Service' }, loadComponent: () => import('./pages/public/terms-of-service/terms-of-service.component').then(c => c.TermsOfServiceComponent) },
      { path: 'how-it-works', data: { title: 'How it Works' }, loadComponent: () => import('./pages/public/how-it-works/how-it-works.component').then(c => c.HowItWorksComponent) },
      { path: 'thank-you', component: ThankYouComponent, data: { title: 'Thank You' }, canActivate: [SignupGuard] },
      { path: 'you-are-in', component: YouAreInComponent, data: { title: `You're In, Babe` }, canActivate: [SignupGuard] },
      {
        path: 'signin',
        component: SigninComponent, data: { title: 'Sign In', authGuardPipe: () => redirectLoggedInTo(['/']) }
      },
      {
        path: 'signup',
        component: SignupQuestionaireComponent, data: { title: 'Sign Up Now' }
      },
      { 
        path: 'vip', data: { title: 'Join as VIP' }, loadComponent: () => import('./pages/public/join-vip/join-vip.component').then(c => c.JoinVIPComponent) 
      },
      { 
        path: 'vip-a', data: { title: 'Join as VIP' }, loadComponent: () => import('./pages/public/join-vip-a/join-vip-a.component').then(c => c.JoinVIPAComponent) 
      },
      {
        path: 'verify',
        component: VerifyEmailAndMobileComponent, data: { title: 'Verify' }
      },
      {
        path: 'general',
        loadChildren: () => import('./pages/private/general/general.module').then(m => m.GeneralModule),
        canActivate: [AuthorizeGuard],
        data: {
          roles: [ROLES.SUBSCRIBER, ROLES.INFLUENCER, ROLES.STYLIST, ROLES.ADMIN]
        }
      },
      {
        path: 'internal',
        loadChildren: () => import('./pages/private/subscriber/subscriber.module').then(m => m.SubscriberModule),
        canActivate: [AuthorizeGuard],
        data: {
          roles: [ROLES.SUBSCRIBER]
        }
      },
      {
        path: 'discover',
        loadChildren: () => import('./pages/private/subscriber/discover/discover.module').then(m => m.DiscoverModule),
      },
      {
        path: 'influencer',
        loadChildren: () => import('./pages/private/influencer/influencer.module').then(m => m.InfluencerModule),
        canActivate: [AuthorizeGuard],
        data: {
          roles: [ROLES.INFLUENCER]
        }
      },
      {
        path: 'waitlist',
        loadChildren: () => import('./pages/public/waitlist/waitlist.module').then(m => m.WaitlistModule)
      },
      {
        path: 'logout', component: SignoutComponent
      },
      {
        path: 'users/:userId/outfits',
        loadChildren: () => import('./pages/public/outfit/outfit.routing').then(m => m.OutfitModule)
      }
    ]
  },
  {
    path: 'console',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/private/common/components/dashboard-layout/dashboard-layout.module').then(m=>m.DashboardLayoutModule),
    data: {
      roles: [ROLES.ADMIN, ROLES.STYLIST]
    },
    providers: [DialogService, MessageService]
  },
  {
    path: 'chat-view',
    canActivate: [AuthorizeGuard],
    data: {
      roles: [ROLES.ADMIN, ROLES.STYLIST]
    },
    loadChildren: () => import('./shared/modules/chat/chat.module').then(m => m.ChatDashboardModule),
  }
];

const MODULES = [SharedModule];

@NgModule({
  imports: [
    ...MODULES,
    RouterModule.forRoot(routes, {
      useHash: false,
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabledNonBlocking'
    })
  ],
  exports: [
    RouterModule
  ],
})
export class AppRoutingModule { }
